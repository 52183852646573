.passages-element-sticky-container {
  margin-bottom: 8px;
  width: 100%;
  // display: flex;
  // align-items: flex-start;
  // flex-direction: column;
}

.passages-element-sticky {
  width: 100%;
  // position: sticky;
  // top: 56px;
  z-index: 1;
  // max-height: calc((3 / 5) * 100vh);
  overflow: auto;
  img {
    max-width: 100%;
  }
}

.passages-element-sticky-search {
  padding: 8px;
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 2;
}
